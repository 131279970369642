/* .App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
} */

html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
}

#page-wrap {
  text-align: center;
}
